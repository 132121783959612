import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { InView } from "react-intersection-observer";

import { colors, easings, getSiteMetaData } from "../utils";
import { assetShape } from "../utils/shapes";

import { Image } from "../atoms";

const BarContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  flex-direction: row;
  align-items: center;
  height: 1.2rem;
`;

const Progress = styled.div`
  width: 80%;
  height: 1rem;
  border-radius: 0.5rem;
  background-color: ${colors.white};
  border-color: ${colors.white};
  border-style: solid;
  border-width: thick;
  display: flex;
  flex-direction: row;
`;

const ProgressDone = styled.div`
  border-radius: 0.5rem;
  width: ${props => (props.inView ? (props.percentFilled + 0.03) * 100 : 0)}%;
  background-color: ${props => props.green};
  transition: width 0.8s ${easings.smooth};
  transition-delay: 0.1s;
`;

const IconDone = styled.div`
  position: relative;
  width: 100%;
  background-color: ${props =>
    props.percentFilled === 1.0 && props.inView ? props.green : colors.none};
  transform: ${props => (props.inView ? `scale(1)` : `scale(0)`)};
  transition: transform 0.35s ${easings.bounce};
  transition-delay: ${props => (props.inView ? 0.8 : 0)}s;
  border-radius: 50%;

  .icon-img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    height: 70%;
    transform: translate(-50%, -50%);
  }

  .icon-not-done {
    opacity: 0.5;
  }
`;

const ProgressIcon = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  transform: translateX(-0.7rem);
  border: solid ${colors.white} thick;
  background-color: ${colors.white};
`;

function ProgressBar({ percentFilled, icon, iconDone }) {
  const { theme } = getSiteMetaData();

  const green = theme === "TH" ? colors.green : colors.greenCK;

  return (
    <InView triggerOnce>
      {({ inView, ref }) => (
        <BarContainer>
          <Progress>
            <ProgressDone
              percentFilled={percentFilled}
              inView={inView}
              ref={ref}
              green={green}
            />
          </Progress>
          <ProgressIcon>
            <IconDone
              percentFilled={percentFilled}
              inView={inView}
              green={green}
            >
              {percentFilled === 1.0 ? (
                <Image image={iconDone} className="icon-img" />
              ) : (
                <Image image={icon} className="icon-img icon-not-done" />
              )}
            </IconDone>
          </ProgressIcon>
        </BarContainer>
      )}
    </InView>
  );
}

ProgressBar.propTypes = {
  percentFilled: PropTypes.number,
  icon: assetShape.isRequired,
  iconDone: assetShape.isRequired
};

ProgressBar.defaultProps = {
  percentFilled: 0
};

export default ProgressBar;

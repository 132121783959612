import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Image from "../atoms/image";
import TopicProgress from "./topicProgress";

import { sizes, colors } from "../utils";
import {
  topicNumberText,
  topicTitleText,
  topicDescriptionText
} from "../utils/textStyles";
import { assetShape, translationFuncShape } from "../utils/shapes";

const Container = styled.div`

  @media (min-width: ${sizes.desktop}) {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  .topic-model {
    display: none;

    @media (min-width: ${sizes.desktop}) {
      display: block;
      width: 50%;
      max-height: 40rem;
      background: none;
      object-fit: cover;
      object-position: top;
    }

    @media (min-width: ${sizes.xxldesktop}) {
      max-height: 48rem;
    }

    @media (min-width: ${sizes.huge}) {
      width: 50%;
      max-height: 55rem;
    }
  }

  .infoBox {
    margin-bottom: 3rem;

    @media (min-width: ${sizes.desktop}) {
      width: 50%;
      margin-bottom: 11rem;
      padding-right: 4%;
    }

    @media (min-width: ${sizes.xxldesktop}) {
      margin-bottom: 16rem;
      padding-right: 6%;
    }

    @media (min-width: ${sizes.huge}) {
      padding-right: 10%;
    }
  }

  .topicNumber {
    ${topicNumberText}
    color: ${colors.accent};
  }

  .topicTitle {
    ${topicTitleText}
    text-transform: uppercase;
    margin-bottom: 0.8rem;

    @media (min-width: ${sizes.desktop}) {
      margin-bottom: 1.2rem;
    }

    @media (min-width: ${sizes.xxldesktop}) {
      margin-bottom: 2rem;
    }
  }

  .topicDesc {
    ${topicDescriptionText}
    margin-bottom: 2rem;

    @media (min-width: ${sizes.desktop}) {
      margin-bottom: 3.875rem;
    }

    @media (min-width: ${sizes.xxldesktop}) {
      margin-bottom: 3rem;
    }

    @media (min-width: ${sizes.huge}) {
      margin-bottom: 3rem;
    }
  }
`;

const LandingSectionHeader = ({
  topicNumber,
  topicName,
  topicDescription,
  lessonIds,
  image,
  t,
  icon,
  iconDone
}) => (
  <Container>
    <div className="infoBox">
      <span className="topicNumber">0{topicNumber}</span>
      <div className="topicTitle">{topicName}</div>
      <div className="topicDesc">{topicDescription}</div>
      <TopicProgress
        lessonIds={lessonIds}
        t={t}
        icon={icon}
        iconDone={iconDone}
      />
    </div>
    {image && <Image className="topic-model" image={image} />}
  </Container>
);

LandingSectionHeader.propTypes = {
  topicNumber: PropTypes.number,
  topicName: PropTypes.string.isRequired,
  topicDescription: PropTypes.string,
  lessonIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  image: assetShape,
  t: translationFuncShape.isRequired,
  icon: assetShape.isRequired,
  iconDone: assetShape.isRequired
};

LandingSectionHeader.defaultProps = {
  topicNumber: 0,
  topicDescription: "",
  image: undefined
};

export default LandingSectionHeader;

import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";

import LocalNavigation from "../containers/localNavigation";
import LandingWelcome from "../components/landingWelcome";
import LessonBuilder from "../components/lessonBuilder";

import { GlobalContext } from "../context/globalContext";
import {
  assetShape,
  localeShape,
  topicShape,
  translationIndexShape
} from "../utils/shapes";
import { makeAnchor, getSiteMetaData, pushTeliumView } from "../utils";
import getTranslationFn from "../utils/translations";

const Home = ({ pageContext: { page, locale, translationIndex } }) => {
  const { state } = useContext(GlobalContext);
  const t = getTranslationFn(translationIndex);

  const {
    title,
    heading,
    section,
    description,
    ckMedia,
    tommyMedia,
    topics
  } = page;

  const { theme } = getSiteMetaData();
  const media = theme === "TH" ? tommyMedia : ckMedia;

  const pageData = {
    page_title: title,
    page_type: "home",
    page_locale: locale.code
  };

  useEffect(() => {
    pushTeliumView({
      tealium_event: "page_view",
      ...pageData
    });

    state.updateState({ page: { ...pageData } });
  }, []);

  // Create sections for local navigation
  const topicSections = topics.map(topic => {
    const anchoredTitle = makeAnchor(topic.title);

    return {
      uniqueName: anchoredTitle,
      label: topic.title,
      path: `#${anchoredTitle}`
    };
  });

  const landingSections = [
    {
      uniqueName: section.toLocaleLowerCase(),
      label: section,
      path: `#${section.toLocaleLowerCase}`
    },
    ...topicSections
  ];

  return (
    <>
      <LocalNavigation sections={landingSections} isVisible hue="light" />
      <LandingWelcome
        title={title}
        heading={heading}
        description={description}
        media={media}
        id={landingSections[0].uniqueName}
        nextSection={landingSections[1].uniqueName}
        t={t}
      />
      <LessonBuilder topics={topics} brand={theme} locale={locale} t={t} />
    </>
  );
};

Home.propTypes = {
  pageContext: PropTypes.shape({
    page: PropTypes.shape({
      title: PropTypes.string.isRequired,
      heading: PropTypes.string.isRequired,
      section: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      tommyMedia: assetShape.isRequired,
      ckMedia: assetShape.isRequired,
      topics: PropTypes.arrayOf(topicShape)
    }).isRequired,
    locale: localeShape.isRequired,
    translationIndex: translationIndexShape.isRequired
  }).isRequired
};

export default Home;

/* global document */
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import animateScrollTo from "animated-scroll-to";

import ButtonChevron from "./buttonChevron";
import ButtonShop from "./buttonShop";

import { colors, sizes, getSiteMetaData } from "../utils";
import {
  heroDescription,
  heroLinks,
  heroSubtitle,
  heroTitle
} from "../utils/textStyles";
import Image from "../atoms/image";
import { assetShape, translationFuncShape } from "../utils/shapes";

const StyledWelcome = styled.div`
  position: relative;
  padding: 8rem 1rem 4rem;

  @media (min-width: ${sizes.tablet}) {
    padding: 12rem 4vw 4rem;
  }

  @media (min-width: ${sizes.desktop}) {
    padding: 16rem 5% 4.625rem 14%;
  }

  @media (min-width: ${sizes.xxldesktop}) {
    padding: 18rem 10% 5rem 20%;
  }

  @media (min-width: ${sizes.huge}) {
    padding: 21rem 15% 7.5rem 24%;
  }

  .row {
    width: 100%;
    margin-bottom: 2rem;

    @media (min-width: ${sizes.desktop}) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 4.375rem;
    }
  }

  .main {
    max-width: 80%;
    margin-bottom: 2rem;

    @media (min-width: ${sizes.desktop}) {
      max-width: unset;
    }
  }

  .column-left {
    h1 {
      margin-bottom: 0.4rem;
    }
    @media (min-width: ${sizes.desktop}) {
      width: 53%;
      padding-top: 2rem;
      padding-right: 10%;
    }

    @media (min-width: ${sizes.huge}) {
      padding-right: 14%; 
    }
  }

  .column-right {
    width: 100%;

    @media (min-width: ${sizes.tablet}) {
      width: 70%;
    }

    @media (min-width: ${sizes.desktop}) {
      width: 47%;
    }
  }

  h1 {
    ${heroTitle}
    color: ${colors.accent};
  }

  h2 {
    ${heroSubtitle}
    margin-bottom: 1rem;
  }

  .description {
    font-weight: 200;
    ${heroDescription}
  }

  .media {
    @media (min-width: ${sizes.desktop}) {
      width: calc(100% + 5vw);
      margin-right: -5vw;
    }
  }

  .button-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (min-width: ${sizes.desktop}) {
      padding-right: 2.68rem;
    }
  }

  .button-wrapper {
    position: relative;

    &::before {
      display: block;
      content: "";
      width: 1rem;
      height: 1px;
      background: ${colors.gray500};
      margin-bottom: 1rem;
    }
  }

  .button-wrapper p {
    ${heroLinks}
    margin-bottom: 1.375rem;
    color: ${colors.accent};
  }

  .button-shop {
    @media (min-width: ${sizes.desktop}) {
      margin-left: -0.5rem;
    }
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 100%;
    background: ${colors.gray100};
    z-index: -1;
  }
`;

const LandingWelcome = props => {
  const { title, heading, description, media, id, nextSection, t } = props;
  let nextSectionElem;
  const hasMounted = useRef(false);

  useEffect(() => {
    hasMounted.current = true;
  }, []);

  const scrollToNext = () => {
    if (hasMounted.current) {
      nextSectionElem = document.getElementById(nextSection);
      animateScrollTo(nextSectionElem);
    }
  };

  const { theme } = getSiteMetaData();
  const shopUrl = theme === "TH" ? t("link-shop-th") : t("link-shop-ck");

  return (
    <StyledWelcome id={id}>
      <div className="background" />
      <div className="row">
        <div className="main column-left">
          <h1>{title}</h1>
          <h2>{heading}</h2>
          <p className="description">{description}</p>
        </div>
        <div className="column-right">
          <div className="media">
            <Image image={media} />
          </div>
        </div>
      </div>
      <div className="button-group">
        {nextSection.length > 2 && (
          <div className="button-wrapper">
            <p>{t("home-start-learning")}</p>
            <ButtonChevron label="Start learning" onClick={scrollToNext} />
          </div>
        )}
        <div className="button-wrapper">
          <p>{t("ready-to-shop")}</p>
          <ButtonShop
            className="button-shop"
            href={shopUrl}
            type="hollow"
            size="medium"
          >
            {t("home-shop-button")}
          </ButtonShop>
        </div>
      </div>
    </StyledWelcome>
  );
};

LandingWelcome.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  media: assetShape.isRequired,
  nextSection: PropTypes.string,
  t: translationFuncShape.isRequired
};

LandingWelcome.defaultProps = {
  nextSection: ""
};

export default LandingWelcome;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { colors, sizes } from "../utils";

const TopicContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: hidden;

  .headDiv {
    display: flex;
    flex-direction: row;
  }

  .numberWrapper {
    position: relative;
  }

  .topicNumberLarge {
    display: none;
    position: absolute;
    color: ${colors.gray100};
    opacity: 0.7;
    pointer-events: none;

    @media (min-width: ${sizes.xxldesktop}) {
      font-size: 170px;
      font-weight: bold;
      display: block;
      height: 170px;
      bottom: 15px;
    }

    @media (min-width: ${sizes.huge}) {
      font-size: 230px;
      bottom: 83px;
    }
  }
`;

const HeadContainer = styled.div`
  display: block;
  width: 100%;
  padding: 4rem 10% 0rem 14%;
  background-color: ${colors.offwhite};

  @media (min-width: ${sizes.desktop}) {
    padding-top: 8rem;
  }

  @media (min-width: ${sizes.xxldesktop}) {
    padding: 7rem 10% 0rem 20%;
  }
`;

const ContentContainer = styled.div`
  background-color: ${colors.gray100};
  padding: 0rem 3% 0rem 3%;

  @media (min-width: ${sizes.desktop}) {
    padding-left: 10%;
  }

  @media (min-width: ${sizes.xxldesktop}) {
    padding: 0rem 8% 0rem 16%;
  }
`;

const LandingPageSection = ({ header, children, id, topicNumber }) => (
  <TopicContainer id={id}>
    <div className="headDiv">
      <div className="numberWrapper">
        <div className="topicNumberLarge">0{topicNumber}</div>
      </div>
      <HeadContainer>{header}</HeadContainer>
    </div>
    <ContentContainer>{children}</ContentContainer>
  </TopicContainer>
);

LandingPageSection.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.element.isRequired,
  topicNumber: PropTypes.number.isRequired,
  id: PropTypes.string
};

LandingPageSection.defaultProps = {
  id: ""
};

export default LandingPageSection;

import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Tick from "../atoms/icons/tick";
import ProgressBar from "./progressBar";

import { colors, sizes, getSiteMetaData } from "../utils";
import { GameContext } from "../context/gameContext";
import { translationFuncShape, assetShape } from "../utils/shapes";

const ProgressLabel = styled.div`
  text-decoration: none;
  text-transform: uppercase;

  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;

  span {
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.5px;

    @media (min-width: ${sizes.huge}) {
      font-size: 16px;
    }
  }
`;

const TextContainer = styled.span`
  display: flex;
  flex-direction: row;
  margin: 0.1rem;
`;

const TextContainer2 = styled.span`
  display: flex;
  flex-direction: row;
  margin-left: 0.3rem;
`;

const TopicCheckMark = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 1.2rem;
  height: 1.2rem;
  margin-left: 0.5rem;
  border-radius: 50%;
  background-color: ${colors.none};
  border-color: ${({ theme }) =>
    theme.brand === "TH" ? colors.green : colors.greenCK};
  border-style: solid;
  border-width: thin;

  .topicTick {
    display: block;
  }
`;

const ColouredSpan = styled.span`
  color: ${props => props.color};
`;

function TopicProgress({ lessonIds, t, icon, iconDone }) {
  const { gameState, isDone } = useContext(GameContext);

  const numberCompleted = lessonIds.filter(val => {
    return isDone(val);
  }).length;

  const completed = numberCompleted === lessonIds.length;

  // Theme colors
  const { theme } = getSiteMetaData();
  const green = theme === "TH" ? colors.green : colors.greenCK;

  function displayCheckMark() {
    if (completed) {
      return (
        <TopicCheckMark>
          <Tick className="topicTick" width={10} height={7} fill={green} />
        </TopicCheckMark>
      );
    }
    return <></>;
  }

  function getPercent() {
    return numberCompleted / lessonIds.length;
  }

  const topicNumber = `${lessonIds.length} `;

  return (
    <>
      <ProgressLabel>
        <TextContainer>
          <ColouredSpan color={green}>{numberCompleted}</ColouredSpan>
          <ColouredSpan color={completed ? green : colors.gray1000}>
            /
          </ColouredSpan>
          <ColouredSpan color={completed ? green : colors.gray1000}>
            {topicNumber}
          </ColouredSpan>
        </TextContainer>
        <TextContainer2>
          <ColouredSpan color={completed ? colors.black : colors.gray1000}>
            {t("home-lessons-completed")}
          </ColouredSpan>
          {displayCheckMark(gameState)}
        </TextContainer2>
      </ProgressLabel>
      <ProgressBar
        percentFilled={getPercent()}
        icon={icon}
        iconDone={iconDone}
      />
    </>
  );
}

TopicProgress.propTypes = {
  lessonIds: PropTypes.arrayOf(PropTypes.string),
  t: translationFuncShape.isRequired,
  icon: assetShape.isRequired,
  iconDone: assetShape.isRequired
};

TopicProgress.defaultProps = {
  lessonIds: []
};

export default TopicProgress;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";

import LessonCard from "./lessonCard";
import LandingSectionHeader from "./landingSectionHeader";
import LandingPageSection from "./landingPageSection";

import { topicShape, translationFuncShape, localeShape } from "../utils/shapes";
import { makeAnchor, sizes } from "../utils";

const Container = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  padding-bottom: 2rem;

  @media (min-width: ${sizes.tablet}) {
    margin-top: 2rem;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media (min-width: ${sizes.desktop}) {
    margin-top: -6rem;
  }

  @media (min-width: ${sizes.xxldesktop}) {
    margin-top: -9rem;
  }

  li {
    width: 90%;
    max-width: 22.5rem;
    margin: 0 auto 20%;

    @media (min-width: ${sizes.tablet}) {
      width: 45%;
      max-width: unset;
      margin: 0rem 1rem 15%;
    }

    @media (min-width: ${sizes.desktop}) {
      width: 29.6788%;
      margin: 0 1.6611% 5rem;
    }

    @media (min-width: ${sizes.xldesktop}) {
      margin-bottom: 8vw;
    }

    @media (min-width: ${sizes.huge}) {
      margin-bottom: 10vw;
    }
  }
`;

const makeHeader = (topic, index, lessons, image, t) => (
  <LandingSectionHeader
    topicName={topic.title}
    topicNumber={index + 1}
    topicDescription={topic.description}
    lessonIds={lessons.map(l => l.id)}
    image={image}
    t={t}
    icon={topic.progressIcon}
    iconDone={topic.progressIconDone}
  />
);

const LessonBuilder = ({ topics, brand, locale, t }) => {
  return topics.map((topic, index) => {
    const anchor = makeAnchor(topic.title);
    const lessons = topic.lessons || [];
    const image = brand === "TH" ? topic.tommyImage : topic.ckImage;
    const [ref, inView] = useInView({ triggerOnce: true });

    return (
      <LandingPageSection
        key={topic.id}
        topicNumber={index + 1}
        header={makeHeader(topic, index, lessons, image, t)}
        id={anchor}
        t={t}
      >
        <Container ref={ref}>
          {lessons.map((lesson, i) => {
            const uniqueKey = lesson.id;
            return (
              <li key={uniqueKey}>
                <LessonCard
                  lesson={lesson}
                  locale={locale}
                  t={t}
                  i={i}
                  inView={inView}
                />
              </li>
            );
          })}
        </Container>
      </LandingPageSection>
    );
  });
};

LessonBuilder.propTypes = {
  topics: PropTypes.arrayOf(topicShape),
  t: translationFuncShape.isRequired,
  brand: PropTypes.string.isRequired,
  locale: localeShape.isRequired
};

export default LessonBuilder;

import React from "react";
import PropTypes from "prop-types";

const ChevronLarge = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="14"
    viewBox="0 0 24 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 2.716l10.118 9.926a1.264 1.264 0 001.762 0L23 2.716 21.251 1 12 10.074 2.75 1 1 2.716z"
      fill="#000"
      fillRule="evenodd"
    />
  </svg>
);

ChevronLarge.propTypes = {
  className: PropTypes.string
};

ChevronLarge.defaultProps = {
  className: ""
};

export default ChevronLarge;

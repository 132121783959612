import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ChevronLarge from "../atoms/icons/chevronLarge";

import { colors, easings } from "../utils";

const StyledChevronButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  background: transparent;
  border-radius: 50%;
  border: 1px solid ${colors.black};
  cursor: pointer;

  &:focus {
    outline: none;
  }

  svg {
    width: 1.25rem;
    height: 0.625rem;
    transform: translateY(0);
    transition: transform 0.3s ${easings.default};
  }

  &:hover svg {
    transform: translateY(50%);
  }
`;

const ButtonChevron = ({ className, onClick, label }) => (
  <StyledChevronButton
    className={className}
    onClick={onClick}
    aria-label={label}
  >
    <ChevronLarge />
  </StyledChevronButton>
);

ButtonChevron.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func
};

ButtonChevron.defaultProps = {
  className: "",
  label: "",
  onClick: () => {}
};

export default ButtonChevron;
